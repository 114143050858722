var Wizjo = {};
$(document).ready(function(){
    Wizjo.ChangeBG();
    Wizjo.MenuToggle();
    Wizjo.AddLightBoxAttrToImage();
});
Wizjo.MenuToggle = function(){
    $('#menu-toggle').click(function(){
        $(this).toggleClass('active');
        if($(this).hasClass('active')) {
            var el = $('.menu'),
                curHeight = el.height(),
                autoHeight = el.css('height', 'auto').height();
            $('.menu').animate({
                height:autoHeight+20,
            },400).css(
                {
                    "display":'block'				
                });
        }
        else{
            $('.menu').animate({height:0},400).css({
                "display":'none'	
            });
        }
    })
}
Wizjo.ChangeBG = function(){
    var el = $(".menu nav ul").find("li.active").children();
    var id = el[0].id;
    var className; 
    if(id==="175"){
        className = 'body-bg2';
    }
    else if(id==="177" || id==="219" || id==="221"){
        className = 'body-bg3';
    }
    else if(id==="205"){
        className = 'body-bg4';
    }
    else if(id==="220"){
        className = 'body-bg5';
    }
    else if(id==="222"){
        className = 'body-bg6';
    }
    else{
        className = 'body-bg1';	
    }
    $('body').removeClass().addClass(className);
    return;
}
Wizjo.AddLightBoxAttrToImage = function(){
        $('.wp-block-gallery').each(function () {
            $(this).find('.blocks-gallery-item').each(function(){
                $(this).find('a').attr('data-lightbox', 'gallery');  
            })
        });
}